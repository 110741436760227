import FetchWrapper from "../common/FetchWrapper";

function getTagsDistinct(callback) {
  FetchWrapper.commonGETRequest("/api/tags/distinct", callback);
}

function getTagsPopularWithCount(callback) {
  FetchWrapper.commonGETRequest("/api/tags/popularWithCount", callback);
}

function savePopularTags(request, callback) {
  FetchWrapper.commonRequest("POST", "/api/tags/popular", request, callback);
}

const TagsAPI = {
  getTagsDistinct,
  getTagsPopularWithCount,
  savePopularTags,
};

export default TagsAPI;
