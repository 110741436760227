import React, {Component} from 'react';

import './UserEditModal.css';
import '../common/Common.css';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import UsersAPI from './UsersAPI';
import md5 from 'md5';

import t from '../common/Translate';

class UserAddModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.user.id ? t("Edit User") : t("Add User"),
      buttonTitle: this.props.user.id ? t("Update") : t("Save"),
      username: this.props.user.username ? this.props.user.username : "",
      password: "",
      email: this.props.user.email ? this.props.user.email : "",
      role: this.props.user.role ? this.props.user.role : 0};

    this.handleChange = this.handleChange.bind(this);
  }

  updateUser() {
    const request = {
      id: this.props.user.id ? this.props.user.id : 0,
      username: this.state.username,
      email: this.state.email,
      role: this.state.role,
      password: this.state.password ? md5(this.state.password) : ""
    };

    this.setState({valueError: false});
    if (!request.username) {
      this.setState({valueError: true});
    }

    if (request.username) {
      UsersAPI.updateUser(request,
      response => {
        if (response.status === 'OK') {
          this.props.handleClose(true);
        }
      });
    }
  }

  onTextFieldChanged(event, field) {
    this.setState({
      [field]: event.target.value
    });

    if (field === "username") {
      this.setState({valueError: false});
    }
  }

  handleChange(event, type) {
    this.setState({'role': event.target.value});
  }

  render() {
    return (
      <Dialog
        open
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        className="custom-edit-user-dialog">

        <DialogTitle className="padding-bottom-0">{this.state.title}</DialogTitle>

        <DialogContent>
          <FormControl className='full-width'>
            <InputLabel htmlFor="role">{t("Role")}</InputLabel>
            <Select
              value={this.state.role}
              onChange={this.handleChange}
              inputProps={{name: 'role', id: 'role'}}>
              <MenuItem value={0}>{t("admin")}</MenuItem>
              <MenuItem value={1}>{t("manager")}</MenuItem>
            </Select>
          </FormControl>
          <div className='separator-20'/>

          <TextField
            autoFocus
            error={this.state.valueError}
            className="margin-bottom-20"
            margin="dense"
            id="username"
            label={t("Enter username")}
            type="text"
            value={this.state.username}
            onChange={(e) => this.onTextFieldChanged(e, "username")}
            fullWidth
          />

          <TextField
            className="margin-bottom-20"
            margin="dense"
            id="email"
            label={t("Enter email")}
            type="text"
            value={this.state.email}
            onChange={(e) => this.onTextFieldChanged(e, "email")}
            fullWidth
          />

          <TextField
            className="margin-bottom-20"
            margin="dense"
            id="value"
            label={t("Enter new password")}
            type="password"
            value={this.state.password}
            onChange={(e) => this.onTextFieldChanged(e, "password")}
            fullWidth
          />

        </DialogContent>

        <DialogActions>
          <Button onClick={(e) => this.props.handleClose()} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={(e) => this.updateUser()} color="primary">
            {this.state.buttonTitle}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default UserAddModal;
