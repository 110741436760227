import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useEffect } from "react";
import "../common/Common.css";
import AppToolbar from "../toolbar/AppToolbar";
import t from "../common/Translate";
import TagsAPI from "./TagsAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: "5rem",
  },
  paper: {
    width: 400,
    height: 430,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function notName(a, b) {
  const bNames = b.map((bEl) => bEl.name);
  return a.filter((value) => bNames.indexOf(value.name) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const immutablySwapArrayItems = (items, firstIndex, secondIndex) =>
  items.map((element, index) => {
    if (index === firstIndex) return items[secondIndex];
    else if (index === secondIndex) return items[firstIndex];
    else return element;
  });

const TagsPopular = () => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  useEffect(() => {
    let rightTemp = [];
    TagsAPI.getTagsPopularWithCount((response) => {
      if (response.status === "OK") {
        rightTemp = response.data;
        setRight(response.data);

        TagsAPI.getTagsDistinct((response) => {
          if (response.status === "OK") {
            setLeft(notName(response.data, rightTemp));
          }
        });
      }
    });
  }, []);

  const savePopularTags = (tagsPopular, callback) => {
    const tagsWithSortNumber = tagsPopular.map((t, i) => ({ ...t, sort: i }));

    TagsAPI.savePopularTags(tagsWithSortNumber, (response) => {
      if (response.error) {
        alert(response.error);
        return;
      }
      callback();
    });
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    const callback = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    };

    savePopularTags(right.concat(leftChecked), callback);
  };

  const handleCheckedLeft = () => {
    const callback = () => {
      setLeft(
        left.concat(rightChecked).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    };

    savePopularTags(not(right, rightChecked), callback);
  };

  const moveLocationUp = (num) => {
    const callback = () => {
      setRight(immutablySwapArrayItems(right, num - 1, num));
    };

    savePopularTags(immutablySwapArrayItems(right, num - 1, num), callback);
  };

  const moveLocationDown = (num) => {
    const callback = () => {
      setRight(immutablySwapArrayItems(right, num + 1, num));
    };

    savePopularTags(immutablySwapArrayItems(right, num + 1, num), callback);
  };

  const customList = (title, items, movable = false) => (
    <>
      <Typography component="h2" variant="h6" align="center">
        {title}
      </Typography>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map((value, numInArray) => {
            const labelId = `transfer-list-item-${value.name}-label`;
            return (
              <ListItem
                key={value.name}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    color="primary"
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${value.name} (${value.count})`}
                />
                {movable && numInArray > 0 && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      moveLocationUp(numInArray);
                    }}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                )}
                {movable && numInArray !== items.length - 1 && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      moveLocationDown(numInArray);
                    }}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                )}
                {movable && numInArray === items.length - 1 && (
                  <div style={{ marginRight: "3rem" }} />
                )}
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </>
  );

  return (
    <div
      className="users-root"
      style={{
        maxWidth: "none",
      }}
    >
      <AppToolbar />
      <div>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList(t("All utilized tags"), left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(t("Popular tags"), right, true)}</Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TagsPopular;
