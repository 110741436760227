import FetchWrapper from "../common/FetchWrapper";

function syncIcons(callback) {
  FetchWrapper.commonGETRequest("/api/entities/import/syncIcons", callback);
}

function syncTracks(callback) {
  FetchWrapper.commonGETRequest("/api/entities/import/importTracks", callback);
}

// TODO don't repeat this 3 times!!!!!!!!!!!!!
const ImportTracksAPI = {
  syncIcons, syncTracks
};

export default ImportTracksAPI;
