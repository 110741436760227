import React, { Component } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import UserInfo from "./UserInfo";
import { Link } from "react-router-dom";

import Session from "../common/Session";
import "./AppToolbar.css";
import t from "../common/Translate";

class AppToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: "",
    };
  }

  isAdmin() {
    return Session.getUser().role === 0;
  }

  render() {
    return (
      <AppBar>
        <Toolbar>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className="dashboard-title"
          >
            {t("WindsScore")}
          </Typography>

          {this.isAdmin() && (
            <Typography className="toolbar-item">
              <Link to="/dashboard">{t("Dashboard")}</Link>
            </Typography>
          )}

          {this.isAdmin() && (
            <Typography className="toolbar-item">
              <Link to="/users">{t("Users")}</Link>
            </Typography>
          )}

          {this.isAdmin() && (
            <Typography className="toolbar-item">
              <Link to="/report">{t("Report")}</Link>
            </Typography>
          )}

          {this.isAdmin() && (
            <Typography className="toolbar-item">
              <Link to="/tags-popular">{t("Popular tags")}</Link>
            </Typography>
          )}

          {this.isAdmin() && (
            <Typography className="toolbar-item">
              <Link to="/import">{t("曲をインポート")}</Link>
            </Typography>
          )}

          <UserInfo />
        </Toolbar>
      </AppBar>
    );
  }
}

export default AppToolbar;
