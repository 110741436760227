var strings = {
  "Enter root category name": "基本カテゴリー名を入力してください",
  "Enter track name": "トラック名を入力してください",
  "Enter category name": "カテゴリー名を入力してください",
  track: "楽曲トラック",
  category: "カテゴリー",
  "Parent category": "親カテゴリー",
  "This action also will remove all subcategories and tracks in this category!":
    "このアクションにより、このカテゴリーの全てのサブカテゴリーとトラックが削除されます！",
  "Remove {#}?": "{#}を削除しますか？",
  "Are you sure you want to remove this {#}?":
    "この{#}を削除してもよろしいですか？",
  'Select category or track for view details or click on "Add" button':
    "詳細を表示するにはカテゴリーまたはトラックを選択するか、「追加」ボタンをクリックしてください",
  "Enter name for searching": "検索されたい名前を入力してください",
  "Enter help title": "ヘルプのタイトルを入力してください",
  "Enter help URL": "ヘルプURLを入力してください",
  "Enter author": "作曲者名を入力してください",
  "Enter performer": "アーティスト名を入力してください",
  "Enter copyright year": "著作権情報を入力してください",
  "Enter price": "価格を入力してください",
  "Track type": "トラックタイプ",
  "Choose file": "ファイルを選択",
  "Select icon": "アイコンを選択",
  Delete: "削除",
  Remove: "削除",
  Save: "保存",
  Statistics: "統計",
  "Track name": "トラック名",
  "Track icon": "トラックアイコン",
  "Played on iOS": "iOSでの再生回数",
  "Played on Android": "Androidでの再生回数",
  "Recorded on iOS": "iOSでの録音回数",
  "Recorded on Android": "Androidでの録音回数",
  Username: "ユーザー名",
  Password: "パスワード",
  "Entered username or password are incorrect":
    "入力されたユーザー名またはパスワードが正しくありません",
  "Sign In": "サインイン",
  "Sign in": "サインイン",
  WindsScore: "WindsStudio",
  Dashboard: "ダッシュボード",
  Users: "ユーザー",
  "Account of current user": "現在のユーザーアカウント",
  "Sign Out": "サインアウト",
  "Edit User": "ユーザーを編集",
  "Add User": "ユーザーを追加",
  Add: "を追加",
  Update: "更新",
  Role: "役割",
  admin: "管理者",
  manager: "マネージャー",
  unknown: "未設定",
  "Enter username": "ユーザー名を入力してください",
  "Enter email": "Eメールアドレスを入力してください",
  "Enter new password": "新しいパスワードを入力してください",
  Cancel: "キャンセル",
  "Remove user": "ユーザーを削除",
  "Are you sure you want to remove this user?":
    "このユーザーを削除してもよろしいですか？",
  "Enter username or email for searching":
    "検索のためユーザー名またはメールアドレスを入力してください",
  Email: "Eメール",
  Actions: "編集",
  "Rows per page": "ページあたりの行数",
  next: "次",
  previous: "前",
  "Choose track type": "トラックタイプを選択",
  "Choose difficulty": "レベルを選択",
  Difficulty: "レベル",
  "Enter duration": "期間を入力してください",
  "Enter item number": "商品番号",
  "File name": "ファイル名",
  Arranger: "編曲者",
  Lyricist: "作詞者",
  Type: "種類",
  "Category type": "カテゴリータイプ",
  Report: "レポート",
  "Generate report": "レポート作成",
  "Start date": "開始日",
  "End date": "終了日",
  "Popular tags": "よく検索されるタグ",
  "Separate tags with comma: J-POP,Classic":
    "複数のタグを入力する場合はカンマ(,)を入れて下さい: J-POP,クラッシック",
  "All utilized tags": "検索されたタグ全て",
  "Enter item tags": "タグを入力" //TODO add translation
};

function t(key, replace) {
  var value = strings[key];
  if (value) {
    if (replace) {
      return value.replace("{#}", replace);
    } else {
      return value;
    }
  }

  return key;
}

export default t;
