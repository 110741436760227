import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

import '../common/Common.css'
import t from '../common/Translate';

import Session from '../common/Session';
import DashboardAPI from '../dashboard/DashboardAPI';

import Button from '@material-ui/core/Button';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import AppToolbar from '../toolbar/AppToolbar';
import CircularProgress from '@material-ui/core/CircularProgress';

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      endDate: new Date()
    };
  }

  handleStartDateChanged(e) {
    this.setState({startDate: e.toDate()});
  }

  handleEndDateChanged(e) {
    this.setState({endDate: e.toDate()});
  }

  generateReport() {
    this.setState({loading: true});

    var that = this;
    var request = {
      startDate: this.state.startDate.getTime(),
      endDate: this.state.endDate.getTime()};
    DashboardAPI.generateReport(request, function(response) {
      if (response.status === 'OK') {
        window.open('/api/report/' + response.message);
        that.setState({loading: false});
      }
    });
  }

  render() {
    if (!Session.isAuthenticated()) {
      return (<Redirect to='/login'/>);
    }

    return (
      <div className="users-root">
        <AppToolbar/>
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              className="margin-right-10"
              variant="inline"
              format="yyyy/MM/DD"
              margin="normal"
              id="date-picker-inline"
              label={t("Start date")}
              value={this.state.startDate}
              onChange={(e) => this.handleStartDateChanged(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              className="margin-right-10"
              variant="inline"
              format="yyyy/MM/DD"
              margin="normal"
              id="date-picker-inline"
              label={t("End date")}
              value={this.state.endDate}
              onChange={(e) => this.handleEndDateChanged(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>

          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={this.state.loading}
            onClick={(e) => this.generateReport()}
            className="generate-report-button">{t("Generate report")}</Button>

          {this.state.loading && <CircularProgress
            className="custom-remove-progress margin-top-28"/>}

        </div>
      </div>
    )
  }
}

export default Report;
