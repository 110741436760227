function commonRequest(method, url, request, callback) {
  return fetch(url, {
      'method': method,
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request),
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        return {"error": "forbidden"};
      }
    })
    .then(callback);
}

function commonGETRequest(url, callback) {
  return fetch(url, {
      'method': 'GET',
      headers: {
        'accept': 'application/json'
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        return {"error": "forbidden"};
      }
    })
    .then(callback);
}

function uploadFile(url, file, callback) {
  var formData = new FormData();
  formData.append(
    "file",
    file,
    file.name
  );

  fetch(url, {
       method: 'POST',
       headers: {
         'Accept': 'application/json',
       },
       body: formData
  }).then((response) =>  {
     if (response.status === 200) {
       return response.json();
     } else {
       return {"error": "forbidden"};
     }
  })
  .then(callback);
}

const FetchWrapper = {
  commonRequest,
  commonGETRequest,
  uploadFile
};

export default FetchWrapper;
