import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import md5 from 'md5';

import {Redirect} from 'react-router-dom';
import Session from '../common/Session.js'
import LoginAPI from './LoginAPI'
import t from '../common/Translate';

import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      loginError: false,
      errors: {username: false, password: false},
      errorMessage: '',
      username: '',
      password: ''
    };
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState(
      {
        loginError: false,
        errors: {
          username: this.state.username.length === 0,
          password: this.state.password.length === 0
        }
      }
    );

    if (this.state.username.length > 0 && this.state.password.length > 0) {
      LoginAPI.auth({
        username: this.state.username,
        password: md5(this.state.password)},
      response => {
        if (response.status === 'OK') {
          Session.updateUser(response.data);

          this.setState({redirect: true});
        } else {
          this.setState({loginError: true, errors: {username: true, password: true}});
        }
      });
    }
  }

  onTextFieldChange(field, event) {
    this.setState({
      errors: {username: false, password: false},
      [field]: event.target.value
    });
  }

  render() {
    if (Session.isAuthenticated()) {
      return (<Redirect to='/dashboard'/>);
    }

    return (
      <Container component="main" maxWidth="xs">
        <div className="login-paper">
          <Typography component="h1" variant="h5">
            {t("Sign in")}
          </Typography>

          <form className="login-form"
                onSubmit={(e) => this.handleSubmit(e)}
                noValidate>

            <TextField
              error={this.state.errors.username}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Username")}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => this.onTextFieldChange("username", e)}
            />

            <TextField
              error={this.state.errors.password}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => this.onTextFieldChange("password", e)}
            />

            {this.state.loginError &&
              <Typography color="error" component="h1" variant="subtitle1">
                {t("Entered username or password are incorrect")}
              </Typography>
            }

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="login-submit"
            >
              {t("Sign In")}
            </Button>

          </form>
        </div>
      </Container>
    )
  }
}

export default Login;

