import React, {Component} from "react";
import {Redirect} from "react-router-dom";

import Session from "../common/Session";
import "./Dashboard.css";

import DashboardAPI from "./DashboardAPI";
import AppToolbar from "../toolbar/AppToolbar";
import RemoveModal from "../common/RemoveModal";
import EditTrackIconModal from "./EditTrackIconModal";
import EditCategoryIconModal from "./EditCategoryIconModal";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";

import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import AudiotrackIcon from "@material-ui/icons/Audiotrack";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import AddIcon from "@material-ui/icons/Add";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

import ExpandLessIcon from "@material-ui/icons/ArrowUpward";
import ExpandMoreIcon from "@material-ui/icons/ArrowDownward";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import t from "../common/Translate";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workspaceHeight: window.innerHeight - 70,
      treeItemsMaxHeight: window.innerHeight - 140,
      loading: false,
      selectedEntity: {},
      selectedInTreeEntity: {},
      entities: [],
      openedDirectories: [],
      tracks: [],
      map: {},
      search: "",
      showRemoveEntityModal: false,
      showTrackIconModalWindow: false,
      showCategoryIconModalWindow: false,
      selectedCategoryIcon: {},
      selectedTrackIcon: {},
      editingMode: false,
      title: "",
      message: "",
      fileForUploading: {},
      trackIcons: [],
      categoryIcons: [],
    };

    this.closeRemoveModal = this.closeRemoveModal.bind(this);
    this.removeSelectedEntity = this.removeSelectedEntity.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.removeCategoryIcon = this.removeCategoryIcon.bind(this);
    this.closeCategoryIconModalWindow =
      this.closeCategoryIconModalWindow.bind(this);
    this.removeTrackIcon = this.removeTrackIcon.bind(this);
    this.closeTrackIconModalWindow = this.closeTrackIconModalWindow.bind(this);
  }

  componentDidMount() {
    this.updateEntities();
    this.updateTrackIcons();
    this.updateCategoryIcons();
  }

  updateTrackIcons() {
    var that = this;
    DashboardAPI.trackImages((response) => {
      if (response.status === "OK") {
        that.setState({trackIcons: response.data});
      }
    });
  }

  updateCategoryIcons() {
    var that = this;
    DashboardAPI.categoryImages((response) => {
      if (response.status === "OK") {
        that.setState({categoryIcons: response.data});
      }
    });
  }

  updateEntities() {
    var that = this;
    DashboardAPI.getEntities((response) => {
      if (response.status === "OK") {
        that.handleEntities(response.data);
      }
    });
  }

  removeTrackIcon() {
    var that = this;
    DashboardAPI.deleteTrackImage(this.state.selectedTrackIcon, (response) => {
      if (response.status === "OK") {
        that.updateTrackIcons();
        that.setState({showTrackIconModalWindow: false});
      }
    });
  }

  closeTrackIconModalWindow() {
    this.setState({selectedTrackIcon: {}, showTrackIconModalWindow: false});
    this.updateTrackIcons();
  }

  removeCategoryIcon() {
    var that = this;
    DashboardAPI.deleteCategoryImage(
      this.state.selectedCategoryIcon,
      (response) => {
        if (response.status === "OK") {
          that.updateCategoryIcons();
          that.setState({showCategoryIconModalWindow: false});
        }
      }
    );
  }

  closeCategoryIconModalWindow() {
    this.setState({
      selectedCategoryIcon: {},
      showCategoryIconModalWindow: false,
    });
    this.updateCategoryIcons();
  }

  handleEntities(entities) {
    var ordered = [];
    var map = {};
    var openedDirectories = [];

    for (var i = 0; i < entities.length; i++) {
      if (!map[entities[i].id]) {
        ordered.push(entities[i]);
        map[entities[i].id] = entities[i];
        this.addChildren(entities, ordered, map, entities[i]);
      }
    }

    if (ordered.length > 0 && this.state.openedDirectories.length === 0) {
      openedDirectories.push(ordered[0].id);
    } else {
      openedDirectories = this.state.openedDirectories.slice();
    }

    this.setState({
      entities: ordered,
      map: map,
      openedDirectories: openedDirectories,
    });
  }

  addChildren(entities, ordered, map, entity) {
    for (var i = 0; i < entities.length; i++) {
      if (!map[entities[i].id] && entities[i].parentId === entity.id) {
        ordered.push(entities[i]);
        map[entities[i].id] = entities[i];
        this.addChildren(entities, ordered, map, entities[i]);
      }
    }
  }

  addEntity(selectedEntity) {
    var that = this;
    var callback = function () {
      that.setState({
        selectedEntity: {
          name: "",
          type: that.state.selectedInTreeEntity.level < 3 ? 0 : 1,
          helpTitle: "",
          helpURL: "",
          price: "0.0",
          icon: "",
          difficulty: "",
          duration: "",
          fileName: "",
          itemNumber: "",
          author: "",
          performer: "",
          copyrightYear: "",
          arranger: "",
          lyricist: "",
          parentId: that.state.selectedInTreeEntity.id
            ? that.state.selectedInTreeEntity.id
            : 0,
          level: that.state.selectedInTreeEntity.id
            ? that.state.selectedInTreeEntity.level + 1
            : 0,
          rootCategoryType: 0,
        },
      });
    };

    if (selectedEntity) {
      this.setState({selectedInTreeEntity: selectedEntity}, callback);
    } else {
      callback();
    }
  }

  onTextFieldChanged(event, field) {
    if (field === "search") {
      this.setState({search: event.target.value});
    } else {
      var selectedEntity = this.state.selectedEntity;
      selectedEntity[field] = event.target.value;

      this.setState({selectedEntity: selectedEntity});
    }
  }

  getSelectedEntityNameTitle() {
    if (this.state.selectedEntity.level === 0) {
      return t("Enter root category name");
    } else if (this.state.selectedEntity.type === 1) {
      return t("Enter track name");
    } else {
      return t("Enter category name");
    }
  }

  saveEntity() {
    var that = this;

    this.setState({loading: true});
    var callback = function (upperResponse) {
      var request = {};
      for (var prop in that.state.selectedEntity) {
        if (prop === "tags") {
          request[prop] = "{" + that.state.selectedEntity[prop] + "}";
        } else {
          request[prop] = that.state.selectedEntity[prop];
        }
      }

      for (var i = 0; i < that.state.entities.length; i++) {
        if (that.state.entities[i].id === that.state.selectedEntity.id) {
          request.position = that.state.entities[i].position;
          break;
        }
      }

      if (upperResponse && upperResponse.data) {
        request.path = upperResponse.data;
      }

      if (that.state.fileForUploading.name) {
        request.fileName = that.state.fileForUploading.name;
      }

      that.openDirectory({}, that.state.map[request.parentId]);
      DashboardAPI.saveEntity(request, function (response) {
        that.updateEntities();
        that.setState({
          selectedEntity: {},
          selectedInTreeEntity: {},
          tracks: [],
          loading: false,
          fileForUploading: {}
        });
      });
    };

    if (this.state.fileForUploading.name) {
      DashboardAPI.uploadFile(this.state.fileForUploading, callback);
    } else {
      callback();
    }
  }

  isAddDisabled() {
    return (
      (this.state.entities.length > 0 && !this.state.selectedInTreeEntity.id) ||
      this.state.selectedInTreeEntity.type === 1
    );
  }

  selectEntity(entity) {
    if (this.state.selectedEntity.id === entity.id) {
      this.setState({
        selectedEntity: {},
        selectedInTreeEntity: {},
        tracks: [],
        fileForUploading: {}
      });
    } else {
      console.log(entity);
      this.setState({
        selectedEntity: entity,
        selectedInTreeEntity: entity,
        tracks: this.getTracks(entity),
        fileForUploading: {}
      });
    }
  }

  getTracks(entity) {
    if (entity.type === 1) return [entity];

    var tracks = [];
    for (var i = 0; i < this.state.entities.length; i++) {
      if (
        this.state.entities[i].parentId === entity.id &&
        this.state.entities[i].type === 1
      ) {
        tracks.push(this.state.entities[i]);
      }
    }

    return tracks;
  }

  removeEntity() {
    var item =
      this.state.selectedEntity.type === 1 ? t("track") : t("category");
    var additional =
      this.state.selectedEntity.type !== 1
        ? t(
          "This action also will remove all subcategories and tracks in this category!"
        )
        : "";

    this.setState({
      showRemoveEntityModal: true,
      title: t("Remove {#}?", item),
      message:
        t("Are you sure you want to remove this {#}?", item) + " " + additional,
    });
  }

  removeSelectedEntity() {
    this.setState({showRemoveEntityModal: false});

    var that = this;
    this.setState({loading: true});

    DashboardAPI.deleteEntity(this.state.selectedEntity, function () {
      that.updateEntities();
      that.setState({
        loading: false,
        selectedEntity: {},
        selectedInTreeEntity: {},
      });
    });
  }

  closeRemoveModal() {
    this.setState({showRemoveEntityModal: false});
  }

  isRowAllowedWithFilter(entity) {
    if (this.state.search === "") {
      return (
        entity.level === 0 ||
        this.state.openedDirectories.indexOf(entity.parentId) !== -1
      );
    } else {
      return (
        entity.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
        -1 ||
        entity.itemNumber
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        entity.tags
          .toString()
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        entity.arranger
          .toString()
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        entity.author
          .toString()
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        entity.copyrightYear
          .toString()
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        entity.difficulty
          .toString()
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        entity.lyricist
          .toString()
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        entity.performer
          .toString()
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    }
  }

  getParentCategory() {
    return this.state.map[this.state.selectedEntity.parentId].name;
  }

  selectIcon(icon) {
    var selectedEntity = this.state.selectedEntity;

    if (selectedEntity.rootCategoryImageId === icon.id) {
      selectedEntity.rootCategoryImageId = 0;
    } else {
      selectedEntity.rootCategoryImageId = icon.id;
    }

    this.setState(selectedEntity);
  }

  selectTrackIcon(icon) {
    var selectedEntity = this.state.selectedEntity;

    if (selectedEntity.trackImageId === icon.id) {
      selectedEntity.trackImageId = 0;
    } else {
      selectedEntity.trackImageId = icon.id;
    }

    this.setState(selectedEntity);
  }

  handleChange(event, type) {
    var selectedEntity = this.state.selectedEntity;
    selectedEntity[type] = event.target.value;

    this.setState({selectedEntity: selectedEntity});
  }

  onFileChange(event) {
    this.setState({fileForUploading: event.target.files[0]});
  }

  closeDirectory(e, entity) {
    e.stopPropagation();

    var openedDirectories = this.state.openedDirectories.slice();
    var index = openedDirectories.indexOf(entity.id);
    if (index !== -1) {
      openedDirectories.splice(index, 1);

      var children = this.getChildren(entity);
      if (children.indexOf(this.state.selectedInTreeEntity.id) !== -1) {
        this.setState({selectedInTreeEntity: {}, selectedEntity: {}});
      }

      for (var i = 0; i < children.length; i++) {
        var childIndex = openedDirectories.indexOf(children[i]);
        if (childIndex !== -1) {
          openedDirectories.splice(childIndex, 1);
        }
      }

      this.setState({openedDirectories: openedDirectories});
    }
  }

  getChildren(entity) {
    var results = [];

    for (var i = 0; i < this.state.entities.length; i++) {
      var child = this.state.entities[i];
      if (child.parentId === entity.id) {
        results.push(child.id);
        results = results.concat(this.getChildren(child));
      }
    }

    return results;
  }

  openDirectory(e, entity) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }

    if (!entity) {
      return;
    }

    var openedDirectories = this.state.openedDirectories.slice();
    var index = openedDirectories.indexOf(entity.id);
    if (index === -1) {
      openedDirectories.push(entity.id);
      this.setState({openedDirectories: openedDirectories});
    }
  }

  isDirectoryOpened(entity) {
    return this.state.openedDirectories.indexOf(entity.id) !== -1;
  }

  changeEditMode() {
    this.setState({editingMode: !this.state.editingMode});
  }

  movingUpAvailable(item) {
    var items = this.getItemsByParentId(item.parentId);
    return (
      this.state.editingMode &&
      items.length > 0 &&
      items.indexOf(item) !== 0 &&
      this.state.selectedInTreeEntity.id === item.id
    );
  }

  movingDownAvailable(item) {
    var items = this.getItemsByParentId(item.parentId);
    return (
      this.state.editingMode &&
      items.length > 0 &&
      items.indexOf(item) !== items.length - 1 &&
      this.state.selectedInTreeEntity.id === item.id
    );
  }

  getItemsByParentId(parentId) {
    var items = [];

    for (var i = 0; i < this.state.entities.length; i++) {
      if (this.state.entities[i].parentId === parentId) {
        items.push(this.state.entities[i]);
      }
    }

    return items;
  }

  moveEntityUp(item) {
    var that = this;
    DashboardAPI.moveEntityUp(item, (response) => {
      if (response.status === "OK") {
        that.handleEntities(response.data);
      }
    });
  }

  moveEntityDown(item) {
    var that = this;
    DashboardAPI.moveEntityDown(item, (response) => {
      if (response.status === "OK") {
        that.handleEntities(response.data);
      }
    });
  }

  addNewTrackIcon() {
    this.setState({showTrackIconModalWindow: true, selectedTrackIcon: {}});
  }

  addNewCategoryIcon() {
    this.setState({
      showCategoryIconModalWindow: true,
      selectedCategoryIcon: {},
    });
  }

  changeTrackIcon(trackIcon) {
    this.setState({
      selectedTrackIcon: trackIcon,
      showTrackIconModalWindow: true,
    });
  }

  changeCategoryIcon(trackIcon) {
    this.setState({
      selectedCategoryIcon: trackIcon,
      showCategoryIconModalWindow: true,
    });
  }

  getTrackPath(parentId) {
    const parent = this.state.entities.find((e) => e.id === parentId);

    return parent.level > 1
      ? this.getTrackPath(parent.parentId) + " ＞ " + parent.name
      : parent.name;
  }

  render() {
    if (!Session.isAuthenticated()) {
      return <Redirect to="/login"/>;
    }

    return (
      <div className="dashboard-root">
        <AppToolbar/>
        <div
          style={{height: this.state.workspaceHeight}}
          className="dashboard-tree"
        >
          <div className="tree-toolbar">
            <TextField
              margin="normal"
              className={
                this.state.entities.length === 0
                  ? "entity-search"
                  : "entity-search-full-width"
              }
              fullWidth
              autoFocus
              name="search"
              label={t("Enter name for searching")}
              id="search"
              value={this.state.search}
              onChange={(e) => this.onTextFieldChanged(e, "search")}
            />

            {this.state.entities.length === 0 && (
              <Button
                onClick={(e) => this.addEntity()}
                className="entity-search-button"
                variant="contained"
                color="primary"
                disabled={this.isAddDisabled()}
              >
                {t("Add")}
              </Button>
            )}

            {this.state.entities.length > 0 && !this.state.editingMode && (
              <EditIcon
                className="item-edit-button"
                onClick={(e) => this.changeEditMode()}
              />
            )}

            {this.state.entities.length > 0 && this.state.editingMode && (
              <SaveIcon
                className="item-edit-button"
                onClick={(e) => this.changeEditMode()}
              />
            )}
          </div>

          <div
            style={{maxHeight: this.state.treeItemsMaxHeight}}
            className="tree-items-wrapper"
          >
            {this.state.entities.map((row) => (
              <React.Fragment key={row.id}>
                {this.isRowAllowedWithFilter(row) && (
                  <div
                    className={
                      this.state.selectedInTreeEntity.id === row.id
                        ? "tree-item-selected"
                        : ""
                    }
                    style={{
                      paddingLeft:
                        row.level * 16 * (this.state.search.length > 0 ? 0 : 1),
                    }}
                  >
                    <span
                      onClick={(e) => this.selectEntity(row)}
                      className="tree-item"
                    >
                      {row.type !== 1 && this.isDirectoryOpened(row) && (
                        <FolderOpenIcon
                          onClick={(e) => this.closeDirectory(e, row)}
                        />
                      )}
                      {row.type !== 1 && !this.isDirectoryOpened(row) && (
                        <FolderIcon
                          onClick={(e) => this.openDirectory(e, row)}
                        />
                      )}
                      {row.type === 1 && <AudiotrackIcon/>}
                      {row.name}
                      {this.state.search && row.type === 1 && (
                        <div style={{color: "#808080", marginLeft: "1.8rem"}}>
                          {this.getTrackPath(row.parentId)}
                        </div>
                      )}
                    </span>

                    {this.state.search.length === 0 &&
                      row.type !== 1 &&
                      !this.state.editingMode && (
                        <AddIcon
                          className="item-add-button"
                          onClick={(e) => this.addEntity(row)}
                        />
                      )}

                    {this.state.search.length === 0 &&
                      this.movingDownAvailable(row) && (
                        <ExpandMoreIcon
                          className="item-add-button"
                          onClick={(e) => this.moveEntityDown(row)}
                        />
                      )}

                    {this.state.search.length === 0 &&
                      this.movingUpAvailable(row) && (
                        <ExpandLessIcon
                          className={
                            !this.movingDownAvailable(row)
                              ? "item-add-button-shifted"
                              : "item-add-button"
                          }
                          onClick={(e) => this.moveEntityUp(row)}
                        />
                      )}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div
          style={{height: this.state.workspaceHeight}}
          className="dashboard-workspace"
        >
          {this.state.selectedEntity.parentId === undefined && (
            <span className="dashboard-info-message">
              {t(
                'Select category or track for view details or click on "Add" button'
              )}
            </span>
          )}

          {this.state.selectedEntity.parentId !== undefined && (
            <div className="entity-details-wrapper">
              {this.state.selectedEntity.parentId !== 0 && (
                <div>
                  <span className="dashboard-parent-message">
                    {t("Parent category")}: {this.getParentCategory()}
                  </span>
                </div>
              )}

              {this.state.selectedEntity.level > 1 && (
                <FormControl className="entity-type-select input-3-line">
                  <InputLabel htmlFor="role">{t("Type")}</InputLabel>
                  <Select
                    disabled={!!this.state.selectedEntity.id}
                    value={this.state.selectedEntity.type}
                    onChange={(e) => this.handleChange(e, "type")}
                    inputProps={{name: "type", id: "type"}}
                  >
                    {this.state.selectedEntity.level < 4 && (
                      <MenuItem value={0}>{t("category")}</MenuItem>
                    )}
                    <MenuItem value={1}>{t("track")}</MenuItem>
                  </Select>
                </FormControl>
              )}

              <TextField
                autoFocus
                margin="dense"
                id="name"
                inputProps={{maxLength: 100}}
                label={this.getSelectedEntityNameTitle()}
                type="text"
                value={this.state.selectedEntity.name}
                onChange={(e) => this.onTextFieldChanged(e, "name")}
                className="input-3-line"
              />

              {this.state.selectedEntity.type === 0 && (
                <TextField
                  margin="dense"
                  id="helpTitle"
                  inputProps={{maxLength: 100}}
                  label={t("Enter help title")}
                  type="text"
                  value={this.state.selectedEntity.helpTitle}
                  onChange={(e) => this.onTextFieldChanged(e, "helpTitle")}
                  className="input-3-line"
                />
              )}

              {this.state.selectedEntity.type === 0 && (
                <TextField
                  margin="dense"
                  id="helpURL"
                  label={t("Enter help URL")}
                  type="text"
                  multiline
                  rowsMax={1}
                  value={this.state.selectedEntity.helpURL}
                  onChange={(e) => this.onTextFieldChanged(e, "helpURL")}
                  className="input-3-line"
                />
              )}

              {this.state.selectedEntity.level === 1 && (
                <FormControl className="entity-type-select input-3-line">
                  <InputLabel htmlFor="role">{t("Category type")}</InputLabel>
                  <Select
                    value={this.state.selectedEntity.rootCategoryType}
                    onChange={(e) => this.handleChange(e, "rootCategoryType")}
                    inputProps={{name: "type", id: "type"}}
                  >
                    <MenuItem value={0}>Winds Score</MenuItem>
                    <MenuItem value={1}>Elevato Music</MenuItem>
                    <MenuItem value={2}>Winds Score +</MenuItem>
                  </Select>
                </FormControl>
              )}

              {this.state.selectedEntity.type === 1 && (
                <TextField
                  margin="dense"
                  id="author"
                  inputProps={{maxLength: 100}}
                  label={t("Enter author")}
                  type="text"
                  value={this.state.selectedEntity.author}
                  onChange={(e) => this.onTextFieldChanged(e, "author")}
                  className="input-3-line-last"
                />
              )}

              {this.state.selectedEntity.type === 1 && (
                <TextField
                  margin="dense"
                  id="copyrightYear"
                  label={t("Enter copyright year")}
                  type="text"
                  multiline
                  rowsMax={4}
                  value={this.state.selectedEntity.copyrightYear}
                  onChange={(e) => this.onTextFieldChanged(e, "copyrightYear")}
                  className="full-width"
                />
              )}

              {this.state.selectedEntity.type === 1 && (
                <TextField
                  margin="dense"
                  id="performer"
                  inputProps={{maxLength: 100}}
                  label={t("Enter performer")}
                  type="text"
                  value={this.state.selectedEntity.performer}
                  onChange={(e) => this.onTextFieldChanged(e, "performer")}
                  className="input-3-line"
                />
              )}

              {this.state.selectedEntity.type === 1 && (
                <TextField
                  margin="dense"
                  id="arranger"
                  label={t("Arranger")}
                  inputProps={{maxLength: 100}}
                  type="text"
                  value={this.state.selectedEntity.arranger}
                  onChange={(e) => this.onTextFieldChanged(e, "arranger")}
                  className="input-3-line"
                />
              )}

              {this.state.selectedEntity.type === 1 && (
                <TextField
                  margin="dense"
                  id="lyricist"
                  label={t("Lyricist")}
                  inputProps={{maxLength: 100}}
                  type="text"
                  value={this.state.selectedEntity.lyricist}
                  onChange={(e) => this.onTextFieldChanged(e, "lyricist")}
                  className="input-3-line-last"
                />
              )}

              {this.state.selectedEntity.type === 1 && (
                <TextField
                  margin="dense"
                  id="difficulty"
                  inputProps={{maxLength: 100}}
                  label={t("Difficulty")}
                  type="text"
                  value={this.state.selectedEntity.difficulty}
                  onChange={(e) => this.onTextFieldChanged(e, "difficulty")}
                  className="input-3-line"
                />
              )}

              {this.state.selectedEntity.type === 1 && (
                <TextField
                  margin="dense"
                  id="itemNumber"
                  label={t("Enter item number")}
                  type="text"
                  inputProps={{maxLength: 100}}
                  value={this.state.selectedEntity.itemNumber}
                  onChange={(e) => this.onTextFieldChanged(e, "itemNumber")}
                  className="input-3-line"
                />
              )}

              {/*this.state.selectedEntity.type === 1 &&
                <TextField
                  margin="dense"
                  id="price"
                  label={t("Enter price")}
                  disabled={true}
                  type="text"
                  value={this.state.selectedEntity.price}
                  onChange={(e) => this.onTextFieldChanged(e, "price")}
                  className="input-3-line"
                />*/}
              <div
                className="MuiFormControl-root input-3-line-last">
                {this.state.selectedEntity.type === 1 && (
                  <Input
                    margin="dense"
                    id="file"
                    label={t("Choose file")}
                    type="file"
                    className="track-file-input"
                    onChange={(e) => this.onFileChange(e)}
                  />
                )}
                {this.state.selectedEntity.type === 1 &&
                  this.state.selectedEntity.fileName && <p className="MuiFormHelperText-root MuiFormHelperText-marginDense" style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>{t("旧ファイル") + this.state.selectedEntity.fileName}</p>}
              </div>

              {this.state.selectedEntity.type === 1 && (
                <TextField
                  margin="dense"
                  id="tags"
                  label={t("Enter item tags")}
                  helperText={t("Separate tags with comma: J-POP,Classic")}
                  type="text"
                  inputProps={{maxLength: 100}}
                  value={this.state.selectedEntity.tags} // array get automatically transformed into string like genre-1,genre-2
                  onChange={(e) => this.onTextFieldChanged(e, "tags")}
                  className="full-width"
                />
              )}

              {this.state.selectedEntity.level === 1 && (
                <div className="category-icons-wrapper">
                  <span className="select-icon">{t("Select icon")}:</span>
                  {this.state.categoryIcons.map((row) => (
                    <div
                      onClick={(e) => this.selectIcon(row)}
                      key={row.id}
                      className={
                        row.id === this.state.selectedEntity.rootCategoryImageId
                          ? "category-icon-selected"
                          : ""
                      }
                    >
                      <EditIcon
                        className="category-icon-edit-button"
                        onClick={(e) => this.changeCategoryIcon(row)}
                      />
                      <img alt={row} src={"/api/category/image/" + row.id}/>
                    </div>
                  ))}

                  <div className="add-icon-wrapper">
                    <AddCircleOutlineIcon
                      className="icon-add-button"
                      onClick={(e) => this.addNewCategoryIcon()}
                    />
                  </div>
                </div>
              )}

              {this.state.selectedEntity.type === 1 && (
                <div className="track-icons-wrapper">
                  <span className="select-icon">{t("Select icon")}:</span>
                  {this.state.trackIcons.map((row) => (
                    <div
                      onClick={(e) => this.selectTrackIcon(row)}
                      key={row.id}
                      className={
                        row.id === this.state.selectedEntity.trackImageId
                          ? "track-icon-selected"
                          : ""
                      }
                    >
                      <EditIcon
                        className="track-icon-edit-button"
                        onClick={(e) => this.changeTrackIcon(row)}
                      />
                      <img alt={row} src={"/api/track/image/" + row.id}/>
                    </div>
                  ))}

                  <div className="add-icon-wrapper">
                    <AddCircleOutlineIcon
                      className="icon-add-button"
                      onClick={(e) => this.addNewTrackIcon()}
                    />
                  </div>
                </div>
              )}

              <div className="buttons-wrapper">
                {this.state.selectedEntity.id > 0 && (
                  <Button
                    onClick={(e) => this.removeEntity()}
                    className="entity-delete-button"
                    variant="contained"
                    disabled={this.state.loading}
                    color="primary"
                  >
                    {t("Delete")}
                  </Button>
                )}

                <Button
                  onClick={(e) => this.saveEntity()}
                  className="entity-save-button"
                  variant="contained"
                  disabled={this.state.loading}
                  color="primary"
                >
                  {t("Save")}
                </Button>
              </div>

              {this.state.tracks.length > 0 && (
                <React.Fragment>
                  <div className="entity-details-separator"/>
                  <span className="statistics-info">{t("Statistics")}:</span>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {this.state.selectedEntity.type === 0 && (
                          <TableCell>{t("Track name")}</TableCell>
                        )}
                        <TableCell>{t("Played on iOS")}</TableCell>
                        <TableCell>{t("Played on Android")}</TableCell>
                        <TableCell>{t("Recorded on iOS")}</TableCell>
                        <TableCell>{t("Recorded on Android")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.tracks.map((row) => (
                        <TableRow key={row.id}>
                          {this.state.selectedEntity.type === 0 && (
                            <TableCell>{row.name}</TableCell>
                          )}
                          <TableCell>{row.playedCountIOS}</TableCell>
                          <TableCell>{row.playedCountAndroid}</TableCell>
                          <TableCell>{row.recordedCountIOS}</TableCell>
                          <TableCell>{row.recordedCountAndroid}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </React.Fragment>
              )}
            </div>
          )}
        </div>

        {this.state.showRemoveEntityModal && (
          <RemoveModal
            title={this.state.title}
            message={this.state.message}
            remove={this.removeSelectedEntity}
            close={this.closeRemoveModal}
          />
        )}

        {this.state.showTrackIconModalWindow && (
          <EditTrackIconModal
            selectedTrackIcon={this.state.selectedTrackIcon}
            remove={this.removeTrackIcon}
            close={this.closeTrackIconModalWindow}
          />
        )}

        {this.state.showCategoryIconModalWindow && (
          <EditCategoryIconModal
            selectedTrackIcon={this.state.selectedCategoryIcon}
            remove={this.removeCategoryIcon}
            close={this.closeCategoryIconModalWindow}
          />
        )}
      </div>
    );
  }
}

export default Dashboard;
