import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import AccountCircle from '@material-ui/icons/AccountCircle';

import Session from '../common/Session'
import './UserInfo.css'

import t from '../common/Translate';

class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {open: false};
  }

  getUsername() {
    var username = Session.getUser().username;
    if (!username) {
      username = Session.getUser().email;
    }

    return username;
  }

  handleSignOut() {
    Session.removeUser();
    this.setState({redirect: '/login'});
  }

  handleMenu(event) {
    this.setState({anchorEl: event.currentTarget, open: true});
  }

  handleClose() {
    this.setState({open: false, anchorEl: null});
  }

  showApplications() {
    this.setState({redirect: '/applications'});
  }

  render() {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />);
    }

    return (
      <div className="user-info">

        <IconButton
          aria-label="Account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>

        <Typography
          component="h1"
          variant="subtitle2"
          color="inherit"
          className="inline"
          noWrap>
          {this.getUsername()}
        </Typography>

        <ArrowDropDown
          className="pointer vertical-middle"
          onClick={(e) => this.handleMenu(e)} />

        <Menu
          id="menu-appbar"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={(e) => this.handleClose()}
        >
          <MenuItem onClick={(e) => this.handleSignOut()}>{t("Sign Out")}</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default UserInfo;
