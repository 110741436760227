import {Grid, makeStyles, Typography,} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import "../common/Common.css";
import AppToolbar from "../toolbar/AppToolbar";
import ImportTracksAPI from "./ImportTracksAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto", marginTop: "5rem",
  }, paper: {
    width: 400, height: 430, overflow: "auto",
  }, button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const SYNC_ICONS_BTN_TITLE = "こちら";
const IMPORT_TRACKS_BTN_TITLE = "こちら";
const SYNCING_STATE_BTN_TITLE = "同期";

const ImportTracks = () => {
  const classes = useStyles();
  const [syncIconsBtnTitle, setSyncIconsBtnTitle] = useState(SYNC_ICONS_BTN_TITLE);
  const [importTracksBtnTitle, setImportTracksBtnTitle] = useState(IMPORT_TRACKS_BTN_TITLE);

  const syncIcons = () => {
    setSyncIconsBtnTitle(SYNCING_STATE_BTN_TITLE);

    ImportTracksAPI.syncIcons((response) => {
      if (response.status === 'OK') {
        alert("アイコンとカテゴリーの更新に成功しました。")
      } else {
        console.log(response)
        alert("予期せぬエラー。デベロッパーにご連絡ください。")
      }

      setSyncIconsBtnTitle(SYNC_ICONS_BTN_TITLE);
    });
  }

  const syncTracks = () => {
    setImportTracksBtnTitle(SYNCING_STATE_BTN_TITLE);

    ImportTracksAPI.syncTracks((response) => {
      if (response.status === 'OK') {
        alert("曲のインポートが成功しました。")
      } else if (response.status === 'WARNING') {
        alert("シート内の情報に誤りがあります。「インポートコメント」を確認してください。")
      } else {
        console.log(response)
        alert("予期せぬエラー。デベロッパーにご連絡ください。")
      }

      setImportTracksBtnTitle(IMPORT_TRACKS_BTN_TITLE);
    });
  }


  return (<div
    className="users-root"
    style={{
      maxWidth: "none",
    }}
  >
    <AppToolbar/>
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
        direction="column"
        style={{
          marginTop: "7rem"
        }}
      >
        <Typography component="h2" variant="h6" align="center">
          ステップ１
        </Typography>
        <Typography component="h2" variant="subtitle2" align="center">
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            disabled={syncIconsBtnTitle === SYNCING_STATE_BTN_TITLE}
            style={{marginLeft: "0.7rem", marginRight: "0.7rem"}}
            onClick={() => syncIcons()}
          >{syncIconsBtnTitle}</Button>をクリックしてGoogleスプレッドシート内のアイコンとカテゴリーリストを最新にする。
        </Typography>
        <Typography component="h2" variant="h6" align="center" style={{
          marginTop: "2rem"
        }}>
          ステップ２
        </Typography>
        <Typography component="h2" variant="subtitle2" align="center"><a href="https://docs.google.com/spreadsheets/d/1p0LYimirWboMEVBtdMjdaM1Qtry-fB145lv4HQ7YvQs/edit#gid=0" target="_blank"
                                                                         rel="noopener noreferrer">スプレッドシート</a>を開く。アイコンのシートに新しいアイコンの名前を入力して下さい。<br/>インポートシートではインポートしたい曲の情報を入力して下さい。</Typography>
        <Typography component="h2" variant="h6" align="center" style={{
          marginTop: "2rem"
        }}>
          ステップ３
        </Typography>
        <Typography component="h2" variant="subtitle2" align="center"><Button
          type="button"
          variant="contained"
          color="primary"
          size="small"
          disabled={importTracksBtnTitle === SYNCING_STATE_BTN_TITLE}
          style={{marginLeft: "0.7rem", marginRight: "0.7rem"}}
          onClick={() => syncTracks()}
        >{importTracksBtnTitle}</Button>をクリックしてスプレッドシートから曲をインポート。<br/>
          インポートが成功した場合はシート内が削除されます。<br/>
          エラーがある場合は「インポートコメント」にコメントが表示され、シート内は削除されません。</Typography>
      </Grid>
    </div>
  </div>);
};

export default ImportTracks;
