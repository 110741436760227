import FetchWrapper from '../common/FetchWrapper';

function getEntities(callback) {
  FetchWrapper.commonGETRequest('/api/entities', callback);
}

function moveEntityUp(request, callback) {
  FetchWrapper.commonGETRequest('/api/entities/up/' + request.id, callback);
}

function moveEntityDown(request, callback) {
  FetchWrapper.commonGETRequest('/api/entities/down/' + request.id, callback);
}

function saveEntity(request, callback) {
  FetchWrapper.commonRequest('POST', '/api/entities', request, callback);
}

function deleteEntity(request, callback) {
  FetchWrapper.commonRequest('DELETE', '/api/entities/' + request.id, request, callback);
}

function uploadFile(file, callback) {
  FetchWrapper.uploadFile('/api/track', file, callback);
}
function trackImages(callback) {
  FetchWrapper.commonGETRequest('/api/track/image', callback);
}
function categoryImages(callback) {
  FetchWrapper.commonGETRequest('/api/category/image', callback);
}

function uploadTrackImage(file, callback) {
  FetchWrapper.uploadFile('/api/track/image', file, callback);
}

function replaceTrackImage(trackImage, file, callback) {
  FetchWrapper.uploadFile('/api/track/image/' + trackImage.id, file, callback);
}

function deleteTrackImage(trackImage, callback) {
  FetchWrapper.commonRequest('DELETE', '/api/track/image/' + trackImage.id, trackImage, callback);
}

function uploadCategoryImage(file, callback) {
  FetchWrapper.uploadFile('/api/category/image', file, callback);
}

function replaceCategoryImage(trackImage, file, callback) {
  FetchWrapper.uploadFile('/api/category/image/' + trackImage.id, file, callback);
}

function deleteCategoryImage(trackImage, callback) {
  FetchWrapper.commonRequest('DELETE', '/api/category/image/' + trackImage.id, trackImage, callback);
}

function generateReport(request, callback) {
  FetchWrapper.commonRequest('POST', '/api/report', request, callback);
}

const DashboardAPI = {
  getEntities,
  saveEntity,
  deleteEntity,
  uploadFile,
  moveEntityUp,
  moveEntityDown,
  trackImages,
  categoryImages,
  uploadTrackImage,
  replaceTrackImage,
  deleteTrackImage,
  uploadCategoryImage,
  replaceCategoryImage,
  deleteCategoryImage,
  generateReport
};

export default DashboardAPI;
