import FetchWrapper from '../common/FetchWrapper';

function getUsers(request, callback) {
  FetchWrapper.commonRequest('POST', '/api/users', request, callback);
}

function removeUser(request, callback) {
  FetchWrapper.commonRequest('DELETE', '/api/users/' + request.id, request, callback);
}

function updateUser(request, callback) {
  FetchWrapper.commonRequest('PUT', '/api/users', request, callback);
}

const UsersAPI = { getUsers, removeUser, updateUser };
export default UsersAPI;
