import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";

import Login from "./login/Login";

import Users from "./users/Users";
import Dashboard from "./dashboard/Dashboard";
import Report from "./report/Report";
import TagsPopular from "./tagsPopular/TagsPopular";
import ImportTracks from "./importTracks/ImportTracks";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { title: "" };
  }

  render() {
    return (
      <div className="App">
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/report" component={Report} />
            <Route exact path="/tags-popular" component={TagsPopular} />
            <Route exact path="/import" component={ImportTracks} />
            <Redirect to="/login" />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
