import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

import './Users.css';
import '../common/Common.css'

import Session from '../common/Session';
import RemoveModal from '../common/RemoveModal';
import UserEditModal from './UserEditModal';
import UsersAPI from './UsersAPI';

import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';

import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

import AppToolbar from '../toolbar/AppToolbar';
import t from '../common/Translate';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUser: {},
      users: [],
      count: 0,
      rowsPerPage: 10,
      page: 0,
      showRemoveUserModal: false,
      title: '',
      message: '',
      showAddUserModal: false,
      sort: 'username',
      sortDesc: false,
      search: ''
    };

    this.updateUsers = this.updateUsers.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.removeSelectedUser = this.removeSelectedUser.bind(this);
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
    this.removeUser = this.removeUser.bind(this);

    this.showEditUserModal = this.showEditUserModal.bind(this);
    this.closeEditUserModal = this.closeEditUserModal.bind(this);

    this.onTextFieldChange = this.onTextFieldChange.bind(this);
  }

  removeSelectedUser() {
    UsersAPI.removeUser(this.state.selectedUser, response => {
      if (response.status === 'OK') {
        this.closeRemoveModal();
        this.updateUsers();
      }
    });
  }

  removeUser(user) {
    if (user.role !== 0) {
      this.setState({
        selectedUser: user,
        showRemoveUserModal: true,
        title: t('Remove user'),
        message: t('Are you sure you want to remove this user?')})
    }
  }

  closeRemoveModal(user) {
    this.setState({selectedUser: {}, showRemoveUserModal: false})
  }

  editUser(user) {
    this.setState({selectedUser: user, showAddUserModal: true})
  }

  showAddUserModal() {
    this.setState({showAddUserModal: true});
  }

  showEditUserModal(user) {
    this.setState({selectedUser: user, showAddUserModal: true});
  }

  closeEditUserModal() {
    this.setState({selectedUser: {}, showAddUserModal: false});
    this.updateUsers();
  }

  componentDidMount() {
    this.updateUsers();
  }

  handleChangePage(event, value) {
    this.setState({page: value}, this.updateUsers);
  }

  handleChangeRowsPerPage(event) {
    this.setState({rowsPerPage: event.target.value}, this.updateUsers);
  }

  updateUsers() {
    const request = {
      page: this.state.page,
      limit: this.state.rowsPerPage,
      search: this.state.search,
      order: this.state.sort,
      orderDesc: this.state.sortDesc
    };

    UsersAPI.getUsers(request,
    response => {
      if (response.status === "OK") {
        this.setState({
          users: response.data.users,
          count: response.data.count
        });
      }
    });
  }

  localizedRole(user) {
    if (user.role === 0) {
      return t('admin');
    } else if (user.role === 1) {
      return t('manager');
    }

    return t('unknown');
  }

  isAdmin() {
    return Session.getUser().role === 0;
  }

  onTextFieldChange(field, event) {
    var that = this;
    this.setState({search: event.target.value}, function() {
      that.updateUsers();
    });
  }

  render() {
    if (!Session.isAuthenticated()) {
      return (<Redirect to='/login'/>);
    }

    return (
      <div className="users-root">
        <AppToolbar/>
        <div>
          <TextField
            margin="normal"
            className="user-search-with-button"
            fullWidth
            autoFocus
            name="search"
            label={t("Enter username or email for searching")}
            id="search"
            value={this.state.search}
            onChange={(e) => this.onTextFieldChange("search", e)}
          />

          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={(e) => this.showAddUserModal()}
            className="users-add-button">{t("Add User")}</Button>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Username")}</TableCell>
                <TableCell>{t("Email")}</TableCell>
                <TableCell>{t("Role")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.users.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{this.localizedRole(row)}</TableCell>
                  <TableCell className={'users-action-cell'}>
                    <Edit
                      className='pointer margin-right-10'
                      color='primary'
                      onClick={(e) => this.showEditUserModal(row)}/>
                    <Delete
                      className='pointer action-icon'
                      color={row.id === 1 ? 'disabled' : 'primary'}
                      onClick={(e) => this.removeUser(row)}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={this.state.count}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}

            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            labelRowsPerPage={t('Rows per page')}
            backIconButtonText={t('previous')}
            nextIconButtonText={t('next')}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />

          {this.state.showRemoveUserModal &&
            <RemoveModal
              title={this.state.title}
              message={this.state.message}
              remove={this.removeSelectedUser}
              close={this.closeRemoveModal} />
          }

          {this.state.showAddUserModal &&
            <UserEditModal
              user={this.state.selectedUser}
              handleClose={this.closeEditUserModal} />
          }
        </div>
      </div>
    )
  }
}

export default Users;
