import React, {Component} from 'react';

import '../common/Common.css';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';

import t from '../common/Translate';
import DashboardAPI from './DashboardAPI';

class EditCategoryIconModal extends Component {

  constructor(props) {
    super(props);
    this.state = {loading: false, fileForUploading: {}}
  }

  remove() {
    this.setState({loading: true});
    this.props.remove();
  }

  save() {
    var that = this;
    if (this.state.fileForUploading.name) {
      if (this.props.selectedTrackIcon.id) {
        DashboardAPI.replaceTrackImage(this.props.selectedTrackIcon, this.state.fileForUploading, function(response) {
          if (response.status === 'OK') {
            that.props.close();
          }
        });
      } else {
        DashboardAPI.uploadTrackImage(this.state.fileForUploading, function(response) {
          if (response.status === 'OK') {
            that.props.close();
          }
        });
      }
    }
  }

  onFileChange(event) {
    this.setState({fileForUploading: event.target.files[0]});
  }

  render() {
    return (
      <Dialog
        open
        onClose={this.props.close}
        aria-labelledby="form-dialog-title">

        <DialogTitle
          className="padding-bottom-0"
          id="form-dialog-title">{t("Track icon")}</DialogTitle>

        <DialogContent>
          <Input
            margin="dense"
            id="file"
            label={t("Choose file")}
            type="file"
            className="track-icon-input"
            onChange={(e) => this.onFileChange(e)}
          />
        </DialogContent>

        <DialogActions>
          {this.props.selectedTrackIcon.id && <Button className='remove-button' disabled={this.state.loading} onClick={(e) => this.remove()}>
            {t("Remove")}
          </Button>}

          <Button onClick={(e) => this.props.close()} color="primary">
            {t("Cancel")}
          </Button>

          <Button onClick={(e) => this.save()} color="primary">
            {t("Save")}
          </Button>

          {this.state.loading && <CircularProgress className="custom-remove-progress"/>}

        </DialogActions>
      </Dialog>
    )
  }
}

export default EditCategoryIconModal;
