import cookie from 'react-cookies'

function isAuthenticated() {
  const user = getUser();
  if (!user) {
    return false;
  }

  return user.username || user.email;
}

function getUser() {
  return cookie.load('session');
}

function updateUser(user) {
  cookie.save('session', JSON.stringify(user));
}

function removeUser() {
  cookie.remove('session')
}

const Session = { getUser, isAuthenticated, updateUser, removeUser };
export default Session;
